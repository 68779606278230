import { ready } from './utilities';

ready(init);

function init() {
  document.addEventListener('click', function (event) {
    const target = findTarget(event.target);
    gtag('event', 'click', {
      'event_category': target.dataset.track,
      'event_label': target.dataset.trackLabel
    });
  });
}

// max 4 levels deep
function findTarget(target) {
  if (target.dataset.track) {
    return target;
  } else if (target.parentElement?.dataset?.track) {
    return target.parentElement;
  } else if (target.parentElement?.parentElement?.dataset?.track) {
    return target.parentElement.parentElement;
  } else if (target.parentElement?.parentElement?.parentElement?.dataset?.track) {
    return target.parentElement.parentElement.parentElement;
  } else {
    return target;
  }
}