import { ready, fetchApiUrl } from './utilities';
import { colors } from './vars';
import { chartGradient } from './chart-helper';

const ACTIVE_CLASS = 'chart-switch__item--active';
const JS_CLASS = 'js-guru-performance-switch';
let chartEl;
let chart;
let performanceData;
let Chart;

ready(() => {
  chartEl = document.getElementById('portfolio-performance-chart');

  if (!chartEl) {
    return;
  }

  import('chart.js-legacy').then(init);
});

async function init(ChartModule) {
  Chart = ChartModule.default;
  const guruId = chartEl.dataset.guruId;
  const response = await fetchApiUrl(
    '/api/v1/portfolio-metrics/performance?guru-id=' + guruId
  );
  performanceData = (await response.json()).reverse();

  initChart(chartEl);

  document.querySelectorAll(`.${JS_CLASS}`).forEach((elem) => {
    elem.addEventListener('click', switchTimeframe);
  });
}

function initChart(chartEl) {
  const ctx = chartEl.getContext('2d');
  const [labels, portfolioPerformance] = getData();

  const data = {
    labels,
    datasets: [
      {
        label: 'Return',
        borderColor: colors.success.hex,
        borderWidth: 2,
        backgroundColor: chartGradient(ctx, colors.success.rgb),
        hoverBackgroundColor: colors.success.hex,
        data: portfolioPerformance,
      },
    ],
  };

  Chart.defaults.global.defaultFontColor = 'rgba(255, 255, 255, 0.4)';
  chart = new Chart(ctx, {
    type: 'line',
    data: data,
    options: {
      animation: {
        duration: 0
      },
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          fontSize: 14,
          usePointStyle: true,
        },
      },
      tooltips: {
        position: 'nearest',
        intersect: false,
        callbacks: {
          title: tooltipTitle,
          label: tooltipLabel,
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              callback: function (date) {
                if (date.getMonth() === 0 && date.getDate() === 1) {
                  return date.getFullYear();
                }
              },
            },
          },
        ]
      },
      legend: {
        display: false
      },
      elements: {
        point: {
          radius: 0
        }
      }
    },
  });
}

function getData(years = 10) {
  let labels = [];
  let portfolioPerformance = [];
  let currentValue = 1;
  let startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - years);

  for (let j in performanceData) {
    if (new Date(performanceData[j].date) >= startDate) {
      labels.push(new Date(performanceData[j].date));
      currentValue = currentValue * performanceData[j].change_percent
      portfolioPerformance.push(currentValue);
    }
  }

  return [labels, portfolioPerformance];
}

function tooltipTitle(tooltipItem) {
  return tooltipItem[0].xLabel.toDateString();
}

function tooltipLabel(tooltipItem) {
  return `Return: ${tooltipItem.yLabel.toFixed(2)}x (${(tooltipItem.yLabel * 100 - 100).toFixed(2)}%)`;
}

function switchTimeframe(event) {
  const targetElem = event.target;
  document.querySelector(`.${ACTIVE_CLASS}.${JS_CLASS}`).classList.remove(ACTIVE_CLASS);
  targetElem.classList.add(ACTIVE_CLASS);

  const [labels, portfolioPerformance] = getData(parseInt(targetElem.dataset.guruPerformance));
  chart.data.labels = labels;
  chart.data.datasets[0].data = portfolioPerformance;
  chart.update();
}
