const ready = require('./utilities').ready;
const optionsHeight = { 'guru-name': 88, 'stock-info': 174 };
let guruUrl, guruText, iframeUrl;

ready(init);

function init() {
  const container = document.querySelectorAll('.js-embed-popup-trigger');

  for (c of container) {
    c.addEventListener('click', show);
  }
}

function show(event) {
  const element = event.target;
  guruUrl = element.getAttribute('data-iframe-guru-url');
  guruText = element.getAttribute('data-iframe-guru-text');
  iframeUrl = element.getAttribute('data-iframe-url');

  updateUrl(event);

  document
    .querySelector('.js-embed-popup')
    .classList.remove('embed-popup--hidden');
  document.body.classList.add('embed-popup__noscroll');

  document
    .querySelector('.js-embed-popup-close')
    .addEventListener('click', hide);
  document
    .querySelector('.embed-popup__overlay')
    .addEventListener('click', hide);
  document.body.addEventListener('keydown', keypress);

  const optionCheckboxes = document.querySelectorAll(
    '.js-embed-popup-show-option'
  );
  for (checkbox of optionCheckboxes) {
    checkbox.addEventListener('change', updateUrl);
  }

  const textarea = document.querySelector('.js-copy-content');
  textarea.addEventListener('focus', function () {
    textarea.select();
  });

  const copyButton = document.querySelector('.js-copy-button');
  copyButton.addEventListener('click', function (event) {
    event.preventDefault();
    textarea.focus();
    document.execCommand('copy');
  });
}

function updateUrl() {
  let height = 615;
  let url = iframeUrl;

  Object.keys(optionsHeight).forEach(function (option) {
    if (!document.querySelector('.js-embed-popup-show-' + option).checked) {
      url += '&hide-' + option + '=true';
      height -= optionsHeight[option];
    }
  });

  document.querySelector('.js-copy-content').innerText =
    '<iframe src="' +
    url +
    '" scrolling="no" frameborder="0" style="width: 100%" height="' +
    height +
    '"></iframe><a href="' +
    guruUrl +
    '" style="position: absolute; opacity: 0; z-index: -1;">' +
    guruText +
    '</a>';
  document.querySelector('.js-embed-popup-iframe').setAttribute('src', url);
}

function keypress(event) {
  if (event.key === 'Escape') {
    hide();
  }
}

function hide() {
  document.body.removeEventListener('keydown', keypress);
  document
    .querySelector('.js-embed-popup')
    .classList.add('embed-popup--hidden');
  document.body.classList.remove('embed-popup__noscroll');
}
