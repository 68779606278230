const THRESHOLD = 720;
const ready = require('./utilities').ready;
let container;

ready(init);

function init() {
  container = document.querySelector('.js-infinite-scrolling');

  if (!container) {
    return;
  }

  addScrollEventListener();

  // delay checkIfNearBottom call because it can cause a style recalculation and
  // slow down the Javascript execution
  window.addEventListener('load', checkIfNearBottom);
}

function checkIfNearBottom() {
  if (
    window.innerHeight + window.scrollY >=
    document.body.offsetHeight - THRESHOLD &&
    hasNextPage()
  ) {
    loadNextPage();
  }
}

function addScrollEventListener() {
  window.addEventListener('scroll', checkIfNearBottom);
}

function removeScrollEventListener() {
  window.removeEventListener('scroll', checkIfNearBottom);
}

function hasNextPage() {
  const nextPage = container.getAttribute('data-next-page');
  return nextPage && nextPage !== 'null';
}

async function loadNextPage() {
  removeScrollEventListener();

  if (
    (container.getAttribute('data-next-page') >= 2 && !!document.querySelector('.sign-up-banner') && !document.querySelector('.sign-up-banner--subscription')) ||
    (container.getAttribute('data-next-page') >= 3 && !!document.querySelector('.sign-up-banner--subscription'))
  ) {
    showSignUpBanner();
  } else {
    const response = await fetch(getUrl());
    const json = await response.json();
    container.setAttribute('data-next-page', json.nextPage);
    container.insertAdjacentHTML('beforeend', json.html);
    addScrollEventListener();
  }
}

function getUrl() {
  let url = new URL(container.getAttribute('data-path'), window.location);
  url.search = new URLSearchParams(url.search.slice(1));
  url.searchParams.append('page', container.getAttribute('data-next-page'));
  url.searchParams.append('format', 'json');
  return url;
}

function showSignUpBanner() {
  document.body.classList.add('show-sign-up-banner');

  gtag('event', 'sign-up-banner', {
    'event_category': 'open',
    'non_interaction': true
  });
}
