const ready = require('./utilities').ready;

ready(init);

function init() {
  const els = document.querySelectorAll('.utilities__shorten-description');
  Array.prototype.forEach.call(els, function (el, i) {
    el.addEventListener('click', open);
  });
}

function open(event) {
  const el = event.target;
  el.classList.remove('utilities__shorten-description');
  el.removeEventListener('click', open);
}
