const { ready, fetchApiUrl, getParent } = require('./utilities');

ready(init);

function init() {
  const followButtons = document.querySelectorAll('.js-follow');

  followButtons.forEach((button) => {
    button.addEventListener('click', onClick);
  });
}

function onClick(event) {
  const element = getParent(event.target, 'js-follow');
  const wantsToFollow = !(element.dataset.isFollowing === 'true');

  element.classList.toggle('follow--is-following', wantsToFollow);
  element.setAttribute('data-is-following', wantsToFollow);

  const body = {
    guru_id: element.dataset.guruId,
    follow: element.dataset.isFollowing,
  };
  fetchApiUrl(
    '/api/v1/follow',
    wantsToFollow ? 'POST' : 'DELETE',
    JSON.stringify(body)
  );

  const category = wantsToFollow ? 'join_group' : 'leave_group';
  gtag('event', category, {
    'group_id': element.dataset.guruId,
    'event_label': element.dataset.location
  });
}
