const ready = require('./utilities').ready;

ready(init);

function init() {
  const cc = localStorage.getItem('cookieconsent');
  if (cc === null) {
    showCookieBanner();
  }
}

function showCookieBanner() {
  var bannerElem = document.querySelector('.js-cookie-consent');
  bannerElem.classList.remove('cookie-consent--hidden');
  bannerElem.querySelector('button').addEventListener('click', cookiesAccepted);
}

function cookiesAccepted() {
  document
    .querySelector('.js-cookie-consent')
    .classList.add('cookie-consent--hidden');
  localStorage.setItem('cookieconsent', 'true');
}
