import { ready } from './utilities';

ready(init);

function init() {
  if (document.querySelector('.js-transaction-scroll')) {
    document.addEventListener('scroll', onscroll);
  }
}

function onscroll() {
  const element = document.querySelector('.js-transaction-scroll');
  const hasScrolledDown = window.scrollY > 100;
  element.classList.toggle(
    'transaction-guru__header-info--border',
    hasScrolledDown
  );
}
