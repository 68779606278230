import { ready } from './utilities';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

let chartEl;
let chart;

ready(() => {
  chartEl = document.getElementById('stock-picks-chart');

  if (!chartEl) {
    return;
  }

  initChart(chartEl);
});

function initChart(chartEl) {
  const ctx = chartEl.getContext('2d');

  const dataStockPicks = [
    21.88, 33.71, 20.73, 18.83, 33.13, 31.15, 28.71, 5.75, 13.59, -7.19, 13.21, -7.39,
    19.66, 9.73, 38.82, 36.08, 34.16, 38.96, 29.93, 35.25, 29.87, 0.83, 11.01, 3.12,
    7.08, 32.75, 28.91, 22.28, 49.48, 38.57, 45.28, 52.44, 42.36, 44.84, -13.03, -27.87,
    -28.22, -21.73, -17.88, 13.89, 17.36, 81.40, 75.24, 43.46
  ];

  const dataSP500 = [
    16.48, 28.86, 21.20, 12.37, 20.48, 14.67, 14.90, 12.90, 6.40, 1.81, -8.59, -3.81,
    7.25, 5.71, 23.28, 16.76, 10.98, 18.21, 14.88, 14.80, 17.75, 2.34, 2.41, 4.76,
    1.51, 15.83, 21.15, 4.63, 15.41, 14.20, 14.88, 40.80, 31.14, 31.14, 11.15, -6.22,
    -4.03, -15.72, -8.14, 7.44, 1.92, 14.68, 24.46, 26.68
  ];

  const average = (data) => data.reduce((sum, value) => sum + value, 0) / data.length;
  const avgStockcircle = average(dataStockPicks);
  const avgSP500 = average(dataSP500);

  chart = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: [
        'Q2 2012', 'Q3 2012', 'Q4 2012', 'Q1 2013', 'Q2 2013', 'Q3 2013', 'Q4 2013',
        'Q1 2014', 'Q2 2014', 'Q3 2014', 'Q4 2014', 'Q1 2015', 'Q2 2015', 'Q3 2015', 'Q4 2015',
        'Q1 2016', 'Q2 2016', 'Q3 2016', 'Q4 2016', 'Q1 2017', 'Q2 2017', 'Q3 2017', 'Q4 2017',
        'Q1 2018', 'Q2 2018', 'Q3 2018', 'Q4 2018', 'Q1 2019', 'Q2 2019', 'Q3 2019', 'Q4 2019',
        'Q1 2020', 'Q2 2020', 'Q3 2020', 'Q4 2020', 'Q1 2021', 'Q2 2021', 'Q3 2021', 'Q4 2021',
        'Q1 2022', 'Q2 2022', 'Q3 2022', 'Q4 2022', 'Q1 2023'
      ],
      datasets: [
        {
          label: 'Stockcircle Stock Picks',
          data: dataStockPicks,
          backgroundColor: 'rgba(60, 157, 14, 1)',
          borderColor: 'rgba(60, 157, 14, 1)',
          borderWidth: 1,
          categoryPercentage: 0.5,
          order: 1
        },
        {
          label: 'S&P 500',
          data: dataSP500,
          backgroundColor: 'rgba(200, 200, 200, 1)',
          borderColor: 'rgba(200, 200, 200, 1)',
          borderWidth: 1,
          categoryPercentage: 0.5,
          order: 1
        },
        {
          label: 'Average Stock Picks (22% p.a.)',
          data: new Array(dataStockPicks.length).fill(avgStockcircle),
          type: 'line',
          borderColor: 'rgba(60, 157, 14, 1)',
          borderWidth: 2,
          borderDash: [5, 5],
          fill: false,
          order: 2,
          pointRadius: 0,
          pointHitRadius: 0
        },
        {
          label: 'Average S&P 500 (12% p.a.)',
          data: new Array(dataSP500.length).fill(avgSP500),
          type: 'line',
          borderColor: 'rgba(200, 200, 200, 1)',
          borderWidth: 2,
          borderDash: [5, 5],
          fill: false,
          order: 2,
          pointRadius: 0,
          pointHitRadius: 0
        }
      ]
    },
    options: {
      scales: {
        x: {
          grid: {
            display: false
          },
          border: {
            display: false
          },
          ticks: {
            callback: function (value, index) {
              return index % 8 === 0 ? this.getLabelForValue(value) : '';
            },
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            display: false
          },
          border: {
            display: false
          },
          ticks: {
            callback: function (value) {
              const allowedValues = [-20, 0, 20, 40, 60, 80];
              if (allowedValues.includes(value)) {
                return value + '%';
              }
            }
          }
        }
      },
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            pointStyle: 'circle'
          }
        },
        tooltip: {
          mode: 'index',
          callbacks: {
            label: function (context) {
              // Only show tooltips for the bar datasets
              if (context.datasetIndex < 2) {
                return context.dataset.label + ': ' + context.raw + '%';
              }
              return null;
            }
          }
        }
      }
    }
  });
}