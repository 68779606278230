const ready = require('./utilities').ready;
const getParent = require('./utilities').getParent;

ready(initializeCharts);

function initializeCharts() {
  const lineCharts = document.querySelectorAll(
    '.js-line-chart-open-on-load .line-chart'
  );
  Array.prototype.forEach.call(lineCharts, function (chart, i) {
    initLineChart(chart);
  });

  // check if donut chart needs to be loaded because it has the CSS class `utilities__hide-on-tablet`
  // and is only visible on desktop
  if (!window.matchMedia('(max-width: 1030px)').matches) {
    const donutCharts = document.querySelectorAll('.donut-chart');
    Array.prototype.forEach.call(donutCharts, function (chart, i) {
      initDonutChart(chart);
    });
  }

  const clickContainer = document.querySelector(
    '.js-line-chart-click-container'
  );

  if (!clickContainer) {
    return;
  }

  const parentClickContainer = clickContainer.parentNode;
  parentClickContainer.addEventListener('mousedown', function (event) {
    const element = getParent(event.target, 'js-line-chart-click-container');
    if (!element) {
      return;
    }

    // only initialize once
    if (element.getAttribute('data-is-initalized') === 'true') {
      return;
    } else {
      element.setAttribute('data-is-initalized', true);
      initLineChart(element.nextElementSibling.querySelector('.line-chart'));
    }
  });
}

function initDonutChart(chart) {
  import('./donut-chart').then(({ default: DonutChart }) => {
    new DonutChart(chart);
  });
}

function initLineChart(chart) {
  import('./line-chart/base').then(({ default: LineChart }) => {
    new LineChart(chart);
  });
}
