import {
  ready,
  fetchApiUrl,
  beautifyNumber,
} from './utilities';
import { colors } from './vars';
import { chartRedGreenGradient } from './chart-helper';

let chartEl;

ready(() => {
  chartEl = document.getElementById('buffett-indicator-chart');

  if (!chartEl) {
    return;
  }

  import('chart.js-legacy').then(init);
});

async function init({ default: Chart }) {
  const ctx = chartEl.getContext('2d');
  const type = chartEl.dataset.type;
  const response = await fetchApiUrl('/api/v1/multiples/' + type);
  const json = await response.json();

  if (json.length === 0) {
    return;
  }

  const labels = [];
  const multiples = [];

  for (let j in json) {
    labels.unshift('Q' + json[j].quarter + ' ' + json[j].year);
    multiples.unshift(parseFloat(json[j].multiple));
  }

  var lineSuperDraw = Chart.controllers.line.prototype.draw;
  Chart.helpers.extend(Chart.controllers.line.prototype, {
    draw: function () {
      var chart = this.chart;
      var ctx = chart.chart.ctx;

      var yRangeBegin = chart.config.data.yRangeBegin;
      var yRangeEnd = chart.config.data.yRangeEnd;

      var xaxis = chart.scales['x-axis-0'];
      var yaxis = chart.scales['y-axis-0'];

      var yRangeBeginPixel = yaxis.getPixelForValue(yRangeBegin);
      var yRangeEndPixel = yaxis.getPixelForValue(yRangeEnd);

      ctx.save();

      for (var yPixel = Math.min(yRangeBeginPixel, yRangeEndPixel); yPixel <= Math.max(yRangeBeginPixel, yRangeEndPixel); ++yPixel) {
        ctx.beginPath();
        ctx.moveTo(xaxis.left, yPixel);
        ctx.strokeStyle = 'rgba(152, 251, 152, 0.05)';
        ctx.lineTo(xaxis.right, yPixel);
        ctx.stroke();
      }

      ctx.restore();

      lineSuperDraw.apply(this, arguments);
    }
  });

  var data = {
    yRangeBegin: 0.4,
    yRangeEnd: 1,
    labels: labels,
    datasets: [
      {
        label: 'Buffett Indicator (GDP / Market Cap)',
        borderColor: colors.success.hex,
        borderWidth: 2,
        backgroundColor: chartRedGreenGradient(ctx),
        hoverBackgroundColor: colors.success.hex,
        data: multiples
      },
    ],
  };

  Chart.defaults.global.defaultFontColor = 'rgba(255, 255, 255, 0.4)';
  new Chart(ctx, {
    type: 'line',
    data: data,
    options: {
      animation: {
        duration: 0
      },
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'index',
        position: 'nearest',
        intersect: false,
        callbacks: {
          label: (value) => {
            const indicator = Math.round((value.value - 1) * 100);
            return Math.abs(indicator) + '% ' + (indicator > 0 ? 'overvalued' : 'undervalued');
          },
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (value) => beautifyNumber(value),
            },
          },
        ],
        xAxes: [{
          ticks: {
            maxTicksLimit: 12,
          },
        }]
      },
    },
  });
}