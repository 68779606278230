import { beautifyNumber } from './utilities';

export function chartLabel(tooltipItem, data) {
  let label = data.datasets[tooltipItem.datasetIndex].label || '';

  if (label) {
    label += ': ';
  }

  label += '$' + beautifyNumber(tooltipItem.yLabel, true);
  return label;
}

export function chartGradient(ctx, rgb) {
  let grad = ctx.createLinearGradient(0, 0, 0, 320);
  grad.addColorStop(0.5, `rgba(${rgb.join()}, 0.2)`);
  grad.addColorStop(1, `rgba(${rgb.join()}, 0)`);
  return grad;
}

export function chartRedGreenGradient(ctx) {
  let grad = ctx.createLinearGradient(-30, 0, 0, 400);
  grad.addColorStop(0.4, `hsla(0deg 100% 49% / 0.3)`);
  grad.addColorStop(0.7, `hsla(48deg 100% 41% / 0.3)`);
  grad.addColorStop(0.75, `hsla(48deg 100% 41% / 0.3)`);
  grad.addColorStop(1, `hsla(132deg 100% 50% / 0.3)`);
  return grad;
}
