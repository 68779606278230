const ready = require('./utilities').ready;

ready(init);

function init() {
  const triggers = document.querySelectorAll('.js-dropdown');
  Array.prototype.forEach.call(triggers, function (trigger, i) {
    new Dropdown(trigger);
  });
}

function Dropdown(trigger) {
  let isOpen = false;
  let dropdown;

  trigger.addEventListener('click', toggleDropdown);

  function toggleDropdown(event) {
    event.preventDefault();
    isOpen = !isOpen;

    dropdown = dropdown || this.parentNode;
    dropdown.classList.toggle('dropdown--active');

    if (isOpen) {
      window.addEventListener('click', close);
    } else {
      window.removeEventListener('click', close);
    }
  }

  function close(event) {
    if (!dropdown.contains(event.target)) {
      dropdown.classList.remove('dropdown--active');
      window.removeEventListener('click', close);
      isOpen = false;
    }
  }
}
