import Combobox from './combobox';
import { ready, fetchApiUrl } from './utilities';

let container;
ready(init);

async function init() {
  container = document.querySelector('.js-politician-table');
  const politicianFilterElem = document.querySelector('#politician-filter');

  if (!politicianFilterElem || !container) {
    return;
  }

  const selectedPolitician = new URLSearchParams(window.location.search).get('politician');
  const politiciansReq = await fetchApiUrl('/api/v1/politicians');
  const politicians = await politiciansReq.json();

  new Combobox({
    valueKey: 'slug',
    selected: selectedPolitician,
    root: politicianFilterElem,
    search: async (text) => {
      return politicians.filter((politician) => {
        return politician.name.toLowerCase().includes(text.toLowerCase());
      });
    },
    render: (politician, value) => {
      const div = document.createElement('div');
      div.textContent = politician.name + (politician.party ? ` (${politician.party})` : '');
      return div;
    },
    update: (value) => {
      updatePage('politician', value);
    }
  });

  // Stock filter
  const stockFilterElem = document.querySelector('#stock-filter');
  const selectedStock = new URLSearchParams(window.location.search).get('stock');

  new Combobox({
    valueKey: 'symbol',
    selected: selectedStock,
    root: stockFilterElem,
    search: async (text) => {
      const stocksReq = await fetchApiUrl('/api/v1/stocks?query=' + text);
      const stocks = await stocksReq.json();
      if (text === '' || 'municipal bonds'.includes(text.toLowerCase())) {
        stocks.unshift({ name: 'Municipal Bonds', symbol: 'municipal-bonds' });
      }
      if (text === '' || 'crypto currencies'.includes(text.toLowerCase()) || 'bitcoin'.includes(text.toLowerCase()) || 'ethereum'.includes(text.toLowerCase())) {
        stocks.unshift({ name: 'Crypto Currencies', symbol: 'crypto' });
      }
      if (text === '' || 'treasury'.includes(text.toLowerCase()) || 'treasuries'.includes(text.toLowerCase())) {
        stocks.unshift({ name: 'US Treasuries', symbol: 'treasury' });
      }
      return stocks;
    },
    render: (stock, value) => {
      const div = document.createElement('div');
      const symbol = ['treasury', 'crypto', 'municipal-bonds'].includes(stock.symbol) ? '' : `(${stock.symbol})`;
      div.textContent = `${stock.name} ${symbol}`;
      return div;
    },
    update: (value) => {
      updatePage('stock', value);
    }
  });

  // Party filter
  let selectedParty = new URLSearchParams(window.location.search).get('party') || 'all';
  const partyButtons = document.getElementsByName('party-filter');
  partyButtons.forEach((button) => {
    if (button.value === selectedParty) {
      button.checked = true;
    }
    button.addEventListener('click', () => {
      selectedParty = button.value;
      updatePage('party', selectedParty === 'all' ? null : selectedParty);
    });
  });
}

async function updatePage(param, newValue) {
  const url = new URL(window.location.href, window.location);
  if (newValue) {
    url.searchParams.set(param, newValue);
  } else {
    url.searchParams.delete(param);
  }
  window.history.pushState({}, '', url);

  url.searchParams.append('format', 'json');
  const response = await fetch(url);
  const json = await response.json();

  container.innerHTML = json.html;
  // used for infinite scrolling
  container.dataset.path = url;
  container.dataset.nextPage = 2;
}

window.addEventListener('popstate', function () {
  if (window.location.pathname === '/congress-stock-trades') {
    window.location.reload();
  }
});