import {
  ready,
  fetchApiUrl,
  beautifyNumber,
} from './utilities';
import { colors } from './vars';
import { chartLabel, chartGradient } from './chart-helper';

let chartEl;

ready(() => {
  chartEl = document.getElementById('multiples-chart');

  if (!chartEl) {
    return;
  }

  import('chart.js-legacy').then(init);
});

async function init({ default: Chart }) {
  const ctx = chartEl.getContext('2d');
  const type = chartEl.dataset.type;
  const response = await fetchApiUrl('/api/v1/multiples/' + type);
  const json = await response.json();

  if (json.length === 0) {
    return;
  }

  let labels = [];
  let multiples = [];

  for (let j in json) {
    labels.unshift(json[j].date);
    multiples.unshift(json[j].multiple);
  }

  var data = {
    labels: labels,
    datasets: [
      {
        label: 'Multiple',
        borderColor: colors.success.hex,
        borderWidth: 2,
        backgroundColor: chartGradient(ctx, colors.success.rgb),
        hoverBackgroundColor: colors.success.hex,
        data: multiples,
      },
    ],
  };

  Chart.defaults.global.defaultFontColor = 'rgba(255, 255, 255, 0.4)';
  let chart = new Chart(ctx, {
    type: 'line',
    data: data,
    options: {
      animation: {
        duration: 0
      },
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'x',
        position: 'nearest',
        intersect: false,
        callbacks: {
          label: (value) => Math.round(value.value * 100) / 100 + 'x'
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              callback: (value) => beautifyNumber(value),
            },
          },
        ],
        xAxes: [{
          type: 'time',
          time: {
            unit: 'month',
            unitStepSize: 12,
            tooltipFormat: 'MMM YYYY',
          },
        }]
      },
    },
  });
}
