const ready = require('./utilities').ready;

ready(init);

function init() {
  const userSource = localStorage.getItem('userSource');
  if (!userSource) {
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = document.referrer;
    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');

    if ((referrer && !referrer.includes('https://stockcircle.com') && !referrer.includes('accounts.google.com') && !referrer.includes('localhost')) || utmCampaign) {
      const source = {
        utmSource: utmSource || null,
        utmMedium: utmMedium || null,
        utmCampaign: utmCampaign || null,
        referrer: referrer || null,
      };
      localStorage.setItem('userSource', JSON.stringify(source));
    }
  }

  // if user visits stock picks landing page, set userSource.landingPage to stockpicks
  if (window.location.pathname === '/pro/stock-picks') {
    const userSource = localStorage.getItem('userSource');
    if (!JSON.parse(userSource).landingPage) {
      const userSourceObj = JSON.parse(userSource);
      userSourceObj.landingPage = 'stockpicks';
      localStorage.setItem('userSource', JSON.stringify(userSourceObj));
    }
  }

  // if just signed up save user source
  if (document.querySelector('.js-just-signed-up')) {
    fetch('/save-user-source', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        source: JSON.parse(userSource)
      })
    }).then(response => {
      // if userSource.landingPage == 'stockpicks', redirect to stock picks landing page
      if (JSON.parse(userSource).landingPage === 'stockpicks') {
        window.location.href = '/pro/stock-picks';
      }
    });
  }
}