const ready = require('./utilities').ready;
const getParent = require('./utilities').getParent;

ready(init);

function init() {
  const container = document.querySelector('.js-hover-parent');
  if (container) {
    container.parentNode.addEventListener('mouseover', mouseoverContainer);
  }
}

function mouseoverContainer(event) {
  const el = getParent(event.target, 'js-hover');
  if (el) {
    mouseover(el);
  }
}

function mouseover(el) {
  const elParent = getParent(event.target, 'js-hover-parent');
  el.classList.add('hover');
  elParent.classList.add('hover');
  el.addEventListener('mouseout', mouseout);
  el.addEventListener('click', click);
}

function mouseout(event) {
  const el = getParent(event.target, 'js-hover');
  const elParent = getParent(event.target, 'js-hover-parent');
  el.classList.remove('hover');
  elParent.classList.remove('hover');
  el.removeEventListener('mouseout', mouseout);
  el.removeEventListener('click', click);
}

function click(event) {
  event.stopPropagation();
}
