// also check vars.scss if you change a variable

export const colors = {
  secondary: color(33, 30, 60),
  danger: color(255, 59, 48),
  success: color(105, 198, 74),
  info: color(24, 143, 255),
};

export const margins = {
  half: 8,
  default: 16,
  double: 32,
  quadruple: 64,
};

function color(r, g, b) {
  return {
    rgb: [r, g, b],
    get hex() {
      let values = [r, g, b].map((val) => {
        let hex = Number(val).toString(16);
        if (hex.length < 2) {
          hex = '0' + hex;
        }
        return hex;
      });
      return '#' + values.join('');
    },
  };
}
