import autocomplete from 'autocompleter';

export default function Combobox({ root, search, render, update, valueKey, selected }) {
  const input = root.querySelector('.js-combobox-input');
  const selectedElem = root.querySelector('.js-combobox-selected');
  const selectedText = root.querySelector('.js-combobox-selected-text');

  if (!input) {
    return;
  }

  if (selected) {
    search(selected.replaceAll('-', ' ')).then((entry) => {
      if (entry[0]) {
        selectItem(entry[0], false);
      }
    });
  }

  autocomplete({
    input: input,
    debounceWaitMs: 0,
    minLength: 0,
    className: 'search__autocomplete',
    fetch: async (text, update) => {
      update(await search(text));
    },
    click: e => e.fetch(),
    onSelect: selectItem,
    render: render,
    emptyMsg: 'No results found',
  });

  selectedElem.addEventListener('click', () => {
    selectedElem.style.display = 'none';
    input.parentElement.style.display = 'block';
    input.focus();
    input.click();
    update(null);
  });

  function selectItem(entry, updatePage = true) {
    selectedText.textContent = entry.name;
    selectedElem.style.display = 'block';
    input.parentElement.style.display = 'none';

    if (updatePage) {
      update(entry[valueKey]);
    }
  }
}
