export function ready(fn) {
  if (
    document.attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

// get parent element with className
export function getParent(node, className) {
  while (node != null) {
    if (node.classList && node.classList.contains(className)) {
      return node;
    }
    node = node.parentNode;
  }
  return false;
}

const secretKey = '6-VUdzeFZooiU_wJNVho';

export function fetchApiUrl(url, method = 'GET', body = null) {
  const headers = new Headers({
    'secret-key': secretKey,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  });
  return fetch(url, { headers: headers, method, body });
}

export function round(number, digits = 2) {
  return +number.toFixed(digits);
}

const BILLION = 1000000000;
const MILLION = 1000000;
const THOUSAND = 1000;

export function beautifyNumber(value, long = false) {
  const absValue = Math.abs(value);
  if (absValue >= BILLION) {
    return round(value / BILLION) + (long ? ' Billion' : 'B');
  } else if (absValue >= MILLION) {
    return round(value / MILLION) + (long ? ' Million' : 'M');
  } else if (absValue >= THOUSAND) {
    return round(value / THOUSAND) + (long ? ' Thousand' : 'K');
  }
  return `${value}`;
}

export function dateToQuarter(date) {
  let quarter = Math.floor((date.getMonth() + 3) / 3);

  return `Q${quarter} ${date.getFullYear()}`;
}
