import autocomplete from 'autocompleter';
import { ready, fetchApiUrl } from './utilities';

const allowedChars = new RegExp(/^[a-zA-Z\s]+$/);
let guruImgPath;
let companyImgPath;

ready(init);

function init() {
  const input = document.querySelector('.js-data-search-input');

  if (!input) {
    return;
  }

  guruImgPath = input.dataset.guruImg;
  companyImgPath = input.dataset.companyImg;

  autocomplete({
    input: input,
    debounceWaitMs: 250,
    className: 'search__autocomplete',
    fetch: function (text, update) {
      const stocksUrl = '/api/v1/stocks?query=' + text;
      const gurusUrl = '/api/v1/gurus?query=' + text;
      Promise.all([fetchApiUrl(stocksUrl), fetchApiUrl(gurusUrl)]).then(
        (responses) => {
          Promise.all(responses.map((r) => r.json())).then((responses) => {
            update(suggestions(responses));
          });
        }
      );
    },
    onSelect: function (item) {
      const name = item.value.split('/').slice(-1)[0];
      gtag('event', 'search', {
        'search_term': item.group + ' - ' + name,
      });
      window.location = item.value;
    },
    render: render,
    emptyMsg: 'No results found',
  });
}

function suggestions([stocks, gurus]) {
  const suggestions = [];

  for (let guru of gurus) {
    suggestions.push({
      image: guruImgPath,
      title: guru.name,
      subtitle: guru.company_name,
      value: guru.path,
      group: 'Gurus',
    });
  }

  for (let stock of stocks) {
    suggestions.push({
      image: 'https://assets.parqet.com/logos/symbol/' + stock.symbol.replaceAll('.', '-'),
      title: stock.name,
      subtitle: stock.symbol,
      value: stock.path,
      group: 'Stocks',
    });
  }

  return suggestions;
}

function render(item, value) {
  const containerElement = document.createElement('div');
  const titleContainerElement = document.createElement('div');
  const titleElement = document.createElement('h3');
  const subtitleElement = document.createElement('p');
  const imgElement = document.createElement('img');

  setSrc(imgElement, item.image);
  insertHighlighedText(titleElement, item.title, value);
  insertHighlighedText(subtitleElement, item.subtitle, value);

  titleContainerElement.prepend(subtitleElement);
  titleContainerElement.prepend(titleElement);
  containerElement.prepend(titleContainerElement);
  containerElement.prepend(imgElement);

  return containerElement;
}

function setSrc(imgElement, src) {
  imgElement.setAttribute('src', src);
  imgElement.addEventListener('error', function () {
    imgElement.setAttribute('src', companyImgPath);
  });
}

function insertHighlighedText(element, text, highlight) {
  if (charsAllowed(highlight)) {
    const regex = new RegExp(highlight, 'gi');
    const inner = text.replace(regex, function (match) {
      return '<strong>' + match + '</strong>';
    });
    element.innerHTML = inner;
  } else {
    element.textContent = text;
  }
}

function charsAllowed(value) {
  return allowedChars.test(value);
}
