const ready = require('./utilities').ready;

ready(init);

function init() {
  const buttons = document.querySelectorAll('.js-subscription-button');
  const switchMonthly = document.querySelector('.js-subscription-switch-monthly');

  if (switchMonthly) {
    switchPlanEventHandler();
  }

  if (!buttons.length) {
    return;
  }

  buttons.forEach(button => {
    button.addEventListener('click', open);
  });
  lemonSqueezyEventHandler();
}

function open(event) {
  let url = 'https://stockcircle.lemonsqueezy.com/checkout/buy';
  const target = event.target;
  let plan = target.dataset.plan;

  if (!plan) {
    const planSwitch = document.querySelector('.js-subscription-switch-monthly');
    plan = planSwitch && planSwitch.checked ? 'monthly' : 'annual';
  }

  if (plan === 'monthly') {
    // url += '/38f61588-8ac4-4c8e-9bff-8bf337a3fb40?enabled=286470'; // dev
    url += '/1b387bd1-38ac-4bd1-88f2-d728088aa81d?enabled=289471'; // monthly
  } else {
    // url += '/926d326c-9bf0-4bf2-befe-30a040c78d41?enabled=286475'; // dev
    url += '/b0978de4-8029-4a13-993b-87e0e547bd85?enabled=289472'; // annual
  }

  const email = document.querySelector('.js-subscription-button').dataset.email;
  const name = document.querySelector('.js-subscription-button').dataset.name;

  url += `&embed=1&media=0&logo=0&checkout[email]=${email}&checkout[name]=${name}`;
  LemonSqueezy.Url.Open(url);
}

function lemonSqueezyEventHandler(count = 0) {
  if (count > 6) {
    return;
  }

  if (typeof LemonSqueezy === 'undefined') {
    window.setTimeout(() => lemonSqueezyEventHandler(count + 1), 200);
    return;
  }

  LemonSqueezy.Setup({
    eventHandler: (event) => {
      if (event.event && event.event === 'Checkout.Success') {
        createSubscription(event.data.order.data.id);
      }
    }
  });
}

function createSubscription(orderId) {
  fetch('/pro', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      order_id: orderId
    })
  }).then(response => {
    return response.json();
  }).then(data => {
    if (data.success) {
      const planSwitch = document.querySelector('.js-subscription-switch-monthly');
      const purchaseValue = planSwitch && planSwitch.checked ? 10.99 : 99;
      fbq('track', 'Purchase', { currency: "USD", value: purchaseValue });
      window.setTimeout(() => window.location.reload(), 2000);
    } else if (data.error) {
      alert(data.error);
    }
  });
}

function switchPlanEventHandler() {
  const switchMonthly = document.querySelector('.js-subscription-switch-monthly');
  const switchAnnual = document.querySelector('.js-subscription-switch-annual');

  switchMonthly.addEventListener('change', () => {
    document.querySelector('.js-subscription-monthly-plan').classList.add('utilities__hidden');
    document.querySelector('.js-subscription-annual-plan').classList.remove('utilities__hidden');
  });

  switchAnnual.addEventListener('change', () => {
    document.querySelector('.js-subscription-annual-plan').classList.add('utilities__hidden');
    document.querySelector('.js-subscription-monthly-plan').classList.remove('utilities__hidden');
  });
}