const ready = require('./utilities').ready;
const getParent = require('./utilities').getParent;

ready(init);

function init() {
  const containers = document.querySelectorAll('.js-accordion-container');
  containers.forEach(function(container) {
    container.addEventListener('click', function (event) {
      const element = getParent(event.target, 'js-accordion');
      if (element) {
        show(element);
      }
    });
  });
}

function show(element) {
  element.classList.toggle('share--is-open');
}
