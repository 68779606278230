import { ready, fetchApiUrl, beautifyNumber, dateToQuarter } from './utilities';
import { colors } from './vars';
import { chartLabel, chartGradient } from './chart-helper';

let chartEl;

ready(() => {
  chartEl = document.getElementById('portfolio-value-chart');

  if (!chartEl) {
    return;
  }

  import('chart.js-legacy').then(init);
});

async function init({ default: Chart }) {
  const guruId = chartEl.dataset.guruId;
  const response = await fetchApiUrl(
    '/api/v1/portfolio-metrics?guru-id=' + guruId
  );
  const json = await response.json();

  initValueChart(chartEl, json);
  initBuySellChart(json);
}

function initValueChart(chartEl, json) {
  const ctx = chartEl.getContext('2d');
  let labels = [];
  let portfolioValue = [];

  for (let j in json) {
    labels.unshift(dateToQuarter(new Date(json[j].date)));
    portfolioValue.unshift(json[j].value);
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Value',
        borderColor: colors.success.hex,
        borderWidth: 2,
        backgroundColor: chartGradient(ctx, colors.success.rgb),
        hoverBackgroundColor: colors.success.hex,
        data: portfolioValue,
      },
    ],
  };

  Chart.defaults.global.defaultFontColor = 'rgba(255, 255, 255, 0.4)';
  new Chart(ctx, {
    type: 'line',
    data: data,
    options: {
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          fontSize: 14,
          usePointStyle: true,
        },
      },
      tooltips: {
        mode: 'x',
        position: 'nearest',
        intersect: false,
        callbacks: {
          label: chartLabel,
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              maxRotation: 0,
              callback: function (value) {
                return value.substr(0, 2) == 'Q1' ? value.substr(3, 4) : null;
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              callback: (value) => beautifyNumber(value),
            },
          },
        ],
      },
    },
  });
}

function initBuySellChart(json) {
  const chartEl = document.getElementById('buy-sell-chart');
  const ctx = chartEl.getContext('2d');
  let labels = [];
  let buyValue = [];
  let sellValue = [];

  for (let j in json) {
    labels.unshift(dateToQuarter(new Date(json[j].date)));
    buyValue.unshift(json[j].buy_value);
    sellValue.unshift(json[j].sell_value);
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Buy',
        borderColor: colors.success.hex,
        borderWidth: 2,
        backgroundColor: chartGradient(ctx, colors.success.rgb),
        hoverBackgroundColor: colors.success.hex,
        data: buyValue,
      },
      {
        label: 'Sell',
        borderColor: colors.danger.hex,
        borderWidth: 2,
        backgroundColor: chartGradient(ctx, colors.danger.rgb),
        hoverBackgroundColor: colors.danger.hex,
        data: sellValue,
      },
    ],
  };

  Chart.defaults.global.defaultFontColor = 'rgba(255, 255, 255, 0.4)';
  new Chart(ctx, {
    type: 'bar',
    data: data,
    options: {
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          fontSize: 14,
          usePointStyle: true,
        },
      },
      tooltips: {
        mode: 'x',
        position: 'nearest',
        intersect: false,
        callbacks: {
          label: chartLabel,
        },
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              maxRotation: 0,
              callback: function (value) {
                return value.substr(0, 2) == 'Q1' ? value.substr(3, 4) : null;
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              callback: (value) => beautifyNumber(value),
            },
          },
        ],
      },
    },
  });
}
